<template>
    <div>
      <q-dialog seamless v-model="subscriptionDialogShown">
        <q-card>
          <q-card-section>
            <div class="text-h6 text-primary text-center">{{ $t('app.subscriptions.name') }}</div>
          </q-card-section>
          <q-separator />
          <q-card-section class="q-pt-none">
            <div class="text-h5 text-primary text-center text-weight-medium q-my-md">
              {{ $tc('app.subscriptions.remaining_trial_days', remainingTrialDays, { days: remainingTrialDays }) }}
            </div>
            <div class="text-h5 text-primary text-center text-weight-medium q-my-md">
              {{ $t('Per poter continuare ad utilizzare AreaPlan attiva l\'abbonamento') }}
            </div>
            <div class="text-h4 text-primary text-center text-weight-bold">
              <template v-if="!loading">{{ $t('app.subscriptions.per_year', { price: `${price}€` }) }}
                <small>({{ $t('app.subscriptions.vat_included') }})</small>
              </template>
              <i v-else class="fa fa-spinner fa-spin"></i>
            </div>
            <div class="text-primary text-center q-my-md" v-html="$t('app.subscriptions.accept_terms')"></div>
          </q-card-section>

          <q-card-actions align="center">
            <q-btn v-if="price" :loading="processing" :label="$t('app.subscriptions.purchase')" color="primary" text-color="white" @click="showDataDialog">
              <template slot="loading">
                <q-spinner class="q-mr-md"></q-spinner> {{ $t('In attesa di conferma') }}
              </template>
            </q-btn>
          </q-card-actions>
        </q-card>
      </q-dialog>

      <invoice-data-dialog v-if="dataDialogShown" :subscribing="true" :on-hide="invoiceDataDialogHidden" :successCallback="subscribe"></invoice-data-dialog>
    </div>
</template>

<script>
import InvoiceDataDialog from 'components/InvoiceDataDialog'
import User from 'src/store/models/User'
import moment from 'moment'

export default {
  name: 'Login',

  components: {
    InvoiceDataDialog
  },

  data () {
    return {
      price: 0,
      checkSubscriptionInterval: null,
      processing: false,
      loading: false,
      subscriptionDialogShown: true,
      dataDialogShown: false
    }
  },
  watch: {
    'me.company.subscribed': function (val, oldVal) {
      if (!oldVal && val) {
        // subscribed, clear interval and redirect
        clearInterval(this.checkSubscriptionInterval)
        this.$router.push({ name: 'projects' })
      }
    }
  },
  computed: {
    me () {
      return User.me()
    },
    remainingTrialDays () {
      if (this.me && this.me.company && !this.me.company.subscribed) {
        if (this.me.company.trial_ends_at) {
          const remainingDays = moment(this.me.company.trial_ends_at).diff(moment(), 'days')
          return remainingDays >= 0 ? remainingDays + 1 : 0
        }
      }
      return 0
    }
  },

  methods: {
    fetchPlan () {
      this.loading = true
      this.$axios.get('/api/plan')
        .then((response) => {
          this.price = response.data.total / 100
        })
        .finally(() => {
          this.loading = false
        })
    },
    // subscribe () {
    //   this.$q.loading.show()
    //   this.me.company.$subscribe()
    //     .then((response) => {
    //       User.$getMe()
    //       this.$q.loading.hide()
    //       this.subscribed()
    //     })
    //     .catch((error) => {
    //       // SCA management
    //       if (error.response && error.response.status === 402) {
    //         this.$q.loading.hide()
    //         const intent = error.response.data.payment_intent

    //         if (intent.status === 'succeeded') {
    //           this.subscribed()
    //         }
    //         if (intent.status === 'requires_action') {
    //           this.stripeClient.handleCardPayment(intent.client_secret).then(result => {
    //             if (result.error) {
    //               this.$q.notify({
    //                 color: 'negative',
    //                 textColor: 'white',
    //                 icon: 'error',
    //                 message: result.error.message,
    //                 position: 'top'
    //               })
    //             } else {
    //               this.subscribed()
    //             }
    //           })
    //         }
    //         if (intent.status === 'requires_confirmation') {
    //           this.stripeClient.confirmCardPayment(intent.client_secret).then(result => {
    //             if (result.error) {
    //               this.$q.notify({
    //                 color: 'negative',
    //                 textColor: 'white',
    //                 icon: 'error',
    //                 message: result.error.message,
    //                 position: 'top'
    //               })
    //             } else {
    //               this.subscribed()
    //             }
    //           })
    //         }
    //       }
    //       this.$q.loading.hide()
    //     })
    // },
    subscribe () {
      this.$q.loading.show()
      this.me.company.$getCheckoutSession().then(data => {
        window.open(data.url, '_blank')
      })
        .catch(() => {
          this.$q.notify({
            color: 'warning',
            textColor: 'white',
            icon: 'warning',
            message: 'Si è verificato un errore, riprova',
            position: 'top'
          })
        })
        .finally(() => {
          this.$q.loading.hide()
        })
    },
    subscribed () {
      this.$q.notify({
        color: 'positive',
        textColor: 'white',
        icon: 'check',
        message: this.$t('app.subscriptions.purchase_success'),
        position: 'top'
      })

      this.processing = true
      this.checkSubscriptionInterval =
        setInterval(function () {
          User.$getMe()
        }, 5000)
    },
    showDataDialog (mode) {
      this.subscribing = mode === 'subscribe'
      if (this.me.company.dataCompleted && this.subscribing) {
        this.subscribe()
      } else {
        this.dataDialogShown = true
      }
    },
    invoiceDataDialogHidden () {
      this.dataDialogShown = false
    }
  },
  beforeMount () {
    this.fetchPlan()
  }
}
</script>
